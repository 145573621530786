.logo {
    font-size: 1.5rem;
    color: #faf1e6;
    border: 1px solid #faf1e6;
    padding: 0 20px;
    text-align: center;
  }

.logo h1 {
    padding: 0;
    margin: 0;
}

.logo-text {
    font-size: .5rem;
    color: #faf1e6;
    padding: 0;
}