.header {
    width: 100%;
    height: 6rem;
    display: flex;
    padding: 0 10%;
    justify-content: space-between;
    align-items: center;
    background-color: #064420
  }
  
